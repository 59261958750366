<template>
  <div>
    <v-container fluid>
      <v-row>
        <v-data-table
          :headers="headers"
          :items="academicGrades"
          class="elevation-1 level1"
          hide-default-footer
          disable-pagination
        >
          <template v-slot:top>
            <v-row class="headerTable-div">
              <v-col cols="12" sm="12" md="4">
                <h3 class="custom-header main-color">
                  {{ $t("Curriculum Setup") }}
                </h3>
              </v-col>
              <v-col cols="6" sm="6" md="2">
                <label for="academicPeriods" class="label">{{
                  $t("Academic Year")
                }}</label>
              </v-col>
              <v-col cols="6" sm="6" md="3">
                <v-select
                  id="academicPeriods"
                  :items="academicPeriods"
                  item-text="en.name"
                  item-value="id"
                  dense
                  outlined
                  v-model="selectedAcademicPeriod"
                  @change="onSelect"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="12" md="3" class="text-center">
                <v-btn
                  icon
                  to="/systemlogging/curriculum_setup"
                  target="_blank"
                  v-if="activitylogPermission"
                >
                  <v-icon large class="main-color" :title="$t('System Log')"
                    >mdi-archive-clock</v-icon
                  >
                </v-btn>
              </v-col>
            </v-row>
          </template>
          <template v-slot:item.grade_name="{ item }">
            <a
              @click="goToGradeSetup(item.id)"
              :title="$i18n.t('Go to grade setup')"
              style="text-decoration: underline; color: #7297ff !important"
            >
              {{ item.grade_name }}
            </a>
          </template>
          <template v-slot:item.supervisor_name="{ item }">
            <v-autocomplete
              :items="supervisors"
              item-text="name"
              item-value="id"
              dense
              outlined
              v-model="item.supervisor_id"
              class="supervisor-select"
              v-if="editable && editableId == item.id"
            ></v-autocomplete>
            <p v-else>{{ item.supervisor_name }}</p>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon
              @click="openEditMode(item)"
              :title="$i18n.t('Edit')"
              v-if="!editable && editableId != item.id"
            >
              edit
            </v-icon>
            <v-icon
              @click="updateSupervisor(item)"
              :title="$i18n.t('Save')"
              v-if="editable && editableId == item.id"
            >
              check
            </v-icon>
            <v-icon
              @click="
                editable = false;
                editableId = null;
              "
              :title="$i18n.t('Cancel')"
              v-if="editable && editableId == item.id"
            >
              cancel
            </v-icon>
          </template>
        </v-data-table>
      </v-row>
    </v-container>
    <v-snackbar
      v-model="snack"
      :timeout="snackTime"
      :color="snackColor"
      :right="true"
      :top="true"
    >
      <!-- {{ snackText }} -->

      <span class="white--text">{{ snackText }}</span>

      <v-btn text @click="snack = false">{{ $t("Close") }}</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
// import refundPaymentVue from "../Payments/refundPayment.vue";
import ACL from "../../acl";

export default {
  name: "curriculumSetup",
  data() {
    return {
      editable: false,
      editableId: "",
      snack: false,
      snackColor: "",
      snackText: "",
      snackTime: 3000,
      supervisors: [],
      selectedAcademicPeriod: "",
      activeAcademicPeriod: "",
      activitylogPermission: ACL.checkPermission("activitylog"),
      headers: [
        {
          text: this.$i18n.t("Grade"),
          value: "grade_name",
        },
        {
          text: this.$i18n.t("Academic Supervisor"),
          value: "supervisor_name",
        },
        {
          text: this.$i18n.t("Actions"),
          value: "actions",
          sortable: false,
          width: "12%",
        },
      ],
      academicPeriods: [],
      academicGrades: [],
      valueSelected: "",
    };
  },
  watch: {
    $route: {
      handler() {
        let academicPeriodId = this.$router.currentRoute.params.academicId;

        if (academicPeriodId != "active") {
          this.selectedAcademicPeriod = Number(academicPeriodId);
        } else {
          this.selectedAcademicPeriod = this.activeAcademicPeriod;
        }
        this.getGrades();
      },
    },
  },
  methods: {
    openEditMode(item) {
      this.editable = true;
      this.editableId = item.id;
    },
    updateSupervisor(item) {
      item.supervisor_name = this.supervisors.find(
        (supervisor) => supervisor.id == item.supervisor_id
      ).name;
      axios
        .post(
          this.getApiUrl + "/school/updateSupervisor/" + item.id,
          { supervisor_id: item.supervisor_id },
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
              //the token is a variable which holds the token
            },
          }
        )
        .then((response) => {
          this.snack = true;
          this.snackText = response.data.status.message;
          this.snackColor = "green";
          this.editableId = "";
          this.editable = false;
          this.getGrades();
        });
    },
    getGrades() {
      axios
        .get(
          this.getApiUrl +
            "/school/getAcademicPeriodGrade/" +
            this.selectedAcademicPeriod,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
              //the token is a variable which holds the token
            },
          }
        )
        .then((response) => {
          this.academicGrades = response.data.data;
        });
    },
    getAllUsers() {
      axios
        .get(this.getApiUrl + "/school/allSupervisors", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          this.supervisors = response.data.data;
        });
    },

    onSelect() {
      if (this.selectedAcademicPeriod) {
        this.$router
          .push({
            path: `/curriculum_setup/` + this.selectedAcademicPeriod,
          })
          // eslint-disable-next-line no-unused-vars
          .catch((err) => {});
      }
    },

    goToGradeSetup(id) {
      // this.$router.push({
      //   path: "GradeYearsRelatedData",
      //   params: { grade_year_id: id }
      // });

      this.$router.push(
        {
          path: "/gradeyears/" + id + "?tab=0&page=1",
        },
        () => {}
      );
    },
  },
  mounted() {
    let academicId = this.$router.currentRoute.params.academicId;
    axios
      .get(this.getApiUrl + "/school/showAcademicPeriod?list=yes", {
        headers: {
          Authorization: "Bearer " + localStorage.token,
          //the token is a variable which holds the token
        },
      })
      .then((response) => {
        this.academicPeriods = response.data.data;

        if (academicId == "active") {
          this.selectedAcademicPeriod = this.academicPeriods.find(
            (object) => object.is_active == 1
          ).id;

          this.activeAcademicPeriod = this.selectedAcademicPeriod;
        } else {
          this.selectedAcademicPeriod = Number(academicId);
        }

        this.getGrades();
        this.getAllUsers();
      });
  },
};
</script>

<style scoped>
.label {
  color: rgba(0, 0, 0, 0.6);
}

.supervisor-select {
  width: 250px;
  margin: 0 auto !important;
}

@media (max-width: 600px) {
  .supervisor-select {
    width: 130px;
  }
}
</style>
